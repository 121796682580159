import React, {useState} from 'react';
import './Hidden.scss';

interface HiddenProps {
    children: React.ReactNode;
}


const Hidden: React.FC<HiddenProps> = ({children}) => {
  const [hidden, setHidden] = useState(true);

  return (
    <div className="hide">
       {hidden || children}
       {hidden || <button className="hide" onClick={() => setHidden(true)}>Näytä vähemmän</button>}
       {hidden && <button className="hide" onClick={() => setHidden(false)}>Näytä enemmän</button>}
    </div>
  );
}

export default Hidden;
