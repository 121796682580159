import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Communications from './communications/Communications'
import Productions from './productions/Productions'

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={Communications}/>
        <Route exact path='/communications' component={Communications}/>
        <Route exact path='/productions/:lang?' component={Productions}/>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
