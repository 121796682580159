import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ReactGA from 'react-ga';
import './index.scss';
import './@types/react-anchor-link-smooth-scroll.d'
import * as serviceWorker from './serviceWorker';

ReactGA.initialize('UA-150940021-1', {
  testMode: process.env.REACT_APP_ENV !== "production",
  debug: process.env.REACT_APP_ENV !== "production"
});
ReactDOM.render((
    <App />
), document.getElementById('root'));

serviceWorker.unregister();
