import React, {useRef, useState} from 'react';
import './Video.scss';

interface VideoProps {
    src: string,
    poster: string
}

const Video: React.FC<VideoProps> = ({src, poster}) => {
  const [muted, setMutedState] = useState(true);
  const videoEl = useRef<HTMLVideoElement>(null);
  const toggleMuted = () => {
    if (videoEl && videoEl.current) {
      videoEl.current.muted = !muted;
      setMutedState(!muted);
    }
  };
  const togglePlay = () => {
    if (videoEl && videoEl.current) {
      if (videoEl.current.paused) {
        videoEl.current.play();
      } else {
        videoEl.current.pause();
      }
    }
  };
  return (
    <span className="video">
      <video ref={videoEl} onClick={togglePlay} poster={poster} autoPlay muted><source src={src}/></video>
      <button className={'toggleMuted ' + muted} onClick={toggleMuted}></button>
    </span>
  );
}

export default Video;
