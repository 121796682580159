import React, {useState, useEffect} from 'react'
import './Communications.scss';
import Video from '../components/Video';
import Hidden from './components/Hidden';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga';
import logo from './images/logo.png'

const Productions: React.FC = () => {
  const [menuClosed, setMenuClosed] = useState(true);
  const toggleMenuClosed = () => setMenuClosed(!menuClosed);

  useEffect(() => { 
    document.title = 'Mediaesiintyminen';
    ReactGA.pageview(window.location.pathname);
  });
  
  return (
    <div className="communications">
      <header>
          <div className="logo">
            <img src={logo} alt="Where's the Beef Communications"/>
          </div>
          <div className="menu">
            <button className={'menuToggle ' + (menuClosed ? 'closed' : 'open')} onClick={toggleMenuClosed}></button>
            <ul className={'menuToggle ' + (menuClosed ? 'closed' : 'open')}>
              <li><AnchorLink href='#mediaesiintyminen'>Mediaesiintyminen - taitolaji</AnchorLink></li>
              <li><AnchorLink href='#referenssit'>Referenssit</AnchorLink></li>
              <li><AnchorLink href='#asiakkaat_sanovat'>Mitä asiakkaat sanovat?</AnchorLink></li>
              <li><AnchorLink href='#ota_yhteytta'>Ota yhteyttä!</AnchorLink></li>
            </ul>
          </div>
      </header>
      <footer>
        <ul>
          <li>Mediakoulutukset</li>
          <li>Kriisiviestintä&shy;koulutukset</li>
          <li>Kirjoittaja&shy;koulutukset</li>
          <li>Räätälöidyt koulutukset</li>
          <li>Yksilövalmennukset, pitkäkestoiset valmennukset</li>
          <li>Juonnot, paneelit, tilaisuuksien suunnittelu</li>
          <li>Käsikirjoitukset, ohjaukset, videotuotannot</li>
        </ul>
        {process.env.REACT_APP_ENV !== "production" && <span className="env"><b>{process.env.REACT_APP_ENV}</b></span>}
      </footer>
      <div className="content">
        <div className="video">
          <Video poster={process.env.PUBLIC_URL + '/comm_video_poster.jpeg'} src={process.env.PUBLIC_URL + '/comm_video.mp4'}/>
        </div>
        <section id="mediaesiintyminen">
          <div className="left">
            <h2>Media&shy;esiintyminen:<br/>valtava mahdollisuus<br/>ja taitolaji</h2>
          </div>
          <div className="right">
            <p>
              Pärjäänkö kameran edessä? Entä miten saan toimittajan vakuutettua? Mitä jos
              esiintymiskammo iskee? Miksei kuvapuhelu tunnu luontevalta?
            </p>
            <p>
              Mediaesiintyminen on taitolaji siinä missä mikä tahansa muukin. Sitä voi ja kannattaa
              harjoitella - ja kannattaa harjoitella erittäin kokeneiden valmentajien ohjauksessa.
            </p>
            <Hidden>
              <p>
                Kautta vuosien olemme saaneet erityistä kiitosta kahdesta asiasta. Ensinnäkin
                paneudumme ja perehdymme etukäteen, jotta ymmärrämme, mitä ja miten asiakkaan
                edustajan olisi julkisuudessa kyettävä kommunikoimaan. Toiseksi annamme jokaiselle
                esiintyjälle yksilöllistä palautetta. Esiintyjänä jokainen käyttää omia
                vahvuuksiaan, eikä yhden viestintätyyli sovi toiselle. Niinpä loistavaan
                esiintymiseen ihmisten tai kameran edessä ei ole mitään muuta kuin oma,
                yksilöllinen tie.
              </p>
              <p>
                Esiintymisen alalla ei myöskään anneta päästötodistusta. Jatkuva oman itsensä
                haastaminen ja asiantuntijoiden kanssa sparraaminen on paras takuu siitä, että
                esiintyminen pysyy freesinä ja uskottavana tiukoissakin tilanteissa.
              </p>
            </Hidden>
          </div>
        </section>
        <section id="referenssit">
        <div className="top">
          <h2>Sopisiko yhteisösi viestintään joku luovempi ratkaisu?</h2>
        </div>
        <div className="bottom">
        <p className="asiakkaat">Asiakkainamme ovat muun muassa</p>
        <span className="asiakas telia"/>
        <span className="asiakas posti"/>
        <span className="asiakas ttl"/>
        <span className="asiakas kamux"/>
        <span className="asiakas lut"/>
        <span className="asiakas thl"/>
        </div>
        </section>
        <section id="asiakkaat_sanovat">
        <div className="top">
          <h2>Miten 25 vuoden media- ja koulutuskokemus näkyy asiakkaiden palautteissa?</h2>
        </div>
        <div className="bottom">
          <span className="comment">
            Erittäin käyttökelpoisia vinkkejä mediassa toimimiseen. Hyvin ja tehokkaasti
            organisoitu koulutus. Osaava kouluttaja&shy;pari&shy;valjakko.
          </span>
          <span className="comment">
            Rento, asiallinen asioiden käsittelytapa. Käytännön esimerkkejä paljon sekä tietoa.
          </span>
          <span className="comment">
            Loistava koulutus, monipuolisesti hyödyllinen ja tarpeellinen. Hyviä vinkkejä
            haastatteluun valmistautumiseen.
          </span>
          <span className="comment">
            Kokonaisuutena erinomainen koulutuspaketti ja erittäin hyödyllinen. Etenkin
            konkreettinen haastattelu&shy;tilanne, sen videointi ja analysointi oli todella tarpeellinen
            ja hienosti toteutettu.
          </span>
          <span className="comment">
            Opin, että asiantuntija osaa kyllä esittää asiansa, kunhan malttaa pysyä alhaisella
            abstraktio&shy;tasolla.
          </span>
          <span className="comment">
            Harvemmin näissä kursseissa on sisältöä. Tässä oli.
          </span>
          <span className="comment">
            Ammattitaitoisesti vedetty ja yritykselle räätälöity mediavalmennus antoi hyvät eväät
            media&shy;kohtaamisiin. Valmennus auttoi hahmottamaan toimittajan näkökulmaa ja ymmärtämään
            valmistautumisen merkityksen. Saimme uusia valmiuksia ja hyödyllistä palautetta
            haastattelu&shy;harjoitusten kautta. Asiatiedon lisäksi oli olennaista hahmottaa
            journalistisen dramaturgian lainalaisuuksia. Päivä oli hyödyllinen, ja kohtaaminen
            rautaisten ammattilaisten kanssa erittäin miellyttävä kokemus.
          </span>
          <span className="comment">
            Yksi hyödyllisimmistä ja hauskimmista koulutuspäivistä, jossa olen ollut.
            Harjoitus&shy;kuvauksissa opin, että jännityksestä huolimatta voi olla pätevä telkkarissa.
            Katson TV-haastatteluita uusin silmin: miten ammatti&shy;toimittaja toimii, miten kiperään
            kysymykseen vastataan, miten asia tehdään ymmärret&shy;tävämmäksi. Osaan nyt valmentaa omia
            asian&shy;tuntijoitamme ja tutkijoitamme TV-esiintymisiin.
          </span>
          <span className="comment">
            Erittäin virikkeellinen koulutus, kouluttajalla niin hyvä asiantuntijuus, että se pisti
            ajattelemaan omaa viestintää. Kouluttajalla hyvä huumorintaju ja ihmistuntemus, ja
            harjoitukset olivat hyvin suunnitellut.
          </span>
          <span className="comment">
            Kaikki sisältö kiinnostavaa ja hyödyllistä. Opetustyyli mukaansa&shy;tempaava ja
            käytännön&shy;läheinen.
          </span>
        </div>
        </section>
        <section id="ota_yhteytta">
        <div className="top">
          <h2>Mitä jos ottaisit yhteyttä?</h2>
          <h2>How about contacting us?</h2>
          <h2>&iexcl;Mandanos un correo!</h2>
        </div>
        <div className="bottom">
          <div className="contact">
            <h3 className="name">Kimmo Räntilä</h3>
            <p className="title">VTM, käsikirjoittaja, ohjaaja, toimittaja</p>
            <p className="email"><a href="mailto:kimmo@wheresthebeef.com">kimmo@wheresthebeef.fi</a></p>
            <p className="phone"><a href="tel:+358-45-6768669">+358-45-6768669</a></p>
          </div>
          <div className="contact">
            <h3 className="name">Tuomo Härkönen</h3>
            <p className="title">MMK, kuvaaja, kouluttaja</p>
          </div>
        </div>
        <div className="links">
          <Link to="/productions">Where's the Beef Productions</Link>
        </div>
        </section>
      </div>
    </div>
  );
}

export default Productions;
