import React, {useEffect} from 'react'
import './Productions.scss';
import Video from '../components/Video';
import { Link, useParams } from 'react-router-dom';
import ReactGA from 'react-ga';
import logo from './images/logo.png';
import i18n from './i18n.json';
const tr: {[header: string]: any } = i18n;

const Productions: React.FC = () => {
  const { lang = "fi" }  = useParams();
  useEffect(() => { 
    document.title = tr.header.title.page[lang];
    ReactGA.pageview(window.location.pathname);
  });

  return (
    <div className="productions">
      <header>
        <div className="logo">
          <img src={logo} alt={tr.header.title.logo[lang]}/>
        </div>
        <div id="ota_yhteytta">
          <h2>{tr.header.contact.title[lang]}</h2>
          <div className="contact">
            <p className="title">{tr.header.contact.kimmo.title[lang]}</p>
            <h3 className="name">Kimmo Räntilä</h3>
            <p className="email"><a href="mailto:kimmo@wheresthebeef.com">kimmo@wheresthebeef.fi</a></p>
            <p className="phone"><a href="tel:+358-45-6768669">+358-45-6768669</a></p>
          </div>
          <div className="contact">
            <p className="title">{tr.header.contact.tuomo.title[lang]}</p>
            <h3 className="name">Tuomo Härkönen</h3>
            <p className="phone"><a href="tel:+358-40-5431463">+358-40-5431463</a> <span className="lighter">({tr.header.contact.sms_preferred[lang]})</span></p>
            <p className="gearlist"><a href={process.env.PUBLIC_URL + '/gearlist.pdf'}>{tr.header.contact.gearlist[lang]}</a></p>
          </div>
        </div>
        <div id="lang">
            {lang === 'fi' ? 
              <Link to="/productions/en"><span className="flag en"></span></Link> :
              <Link to="/productions/fi"><span className="flag fi"></span></Link>}
          </div>
      </header>
      <div className="content">
        <div className="video">
          <Video poster={process.env.PUBLIC_URL + '/prod_video_poster.jpeg'} src={process.env.PUBLIC_URL + '/prod_video.mp4'}/>
        </div>
        <div className="links">
          <Link to="/communications">{tr.content.links.communications[lang]}</Link>
        </div>
      </div>
      <footer>
        <ul>
          {tr.footer.activities[lang].map((i: string, k: string) => <li key={k}>{i}</li>)}
        </ul>
        {process.env.REACT_APP_ENV !== "production" && <span className="env"><b>{process.env.REACT_APP_ENV}</b></span>}
      </footer>
    </div>
  );
}

export default Productions;
